@charset "UTF-8";
/******************************
 3RD PARTY PLUGIN CUSTOMIZATION 
******************************/
/* Cubic Bezier Transition */
/***
Bootstrap Colorpicker
***/
.input-group.color .input-group-btn i {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 6px; }

.colorpicker.dropdown-menu {
  padding: 5px; }

/* change z-index when opened in modal */
.modal-open .colorpicker {
  z-index: 10055 !important; }

/***
Bootstrap Datepaginator
***/
.datepaginator a {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 300; }

.datepicker .today {
  background-image: none !important;
  filter: none !important; }

#dp-calendar {
  right: 4px !important; }

.datepaginator .fa-angle-right:before {
  content: ""/*rtl:""*/; }

.datepaginator .fa-angle-left:before {
  content: ""/*rtl:""*/; }

/***
Bootstrap Datepicker
***/
.datepicker.dropdown-menu {
  padding: 5px; }

.datepicker .selected {
  background-color: #909090 !important;
  background-image: none !important;
  filter: none !important; }

.datepicker .active {
  background-color: #4b8df8 !important;
  background-image: none !important;
  filter: none !important; }

.datepicker .active:hover {
  background-color: #2678FC !important;
  background-image: none !important;
  filter: none !important; }

.datepicker .input-daterange input {
  text-align: left; }

/* change z-index when opened in modal */
.modal-open .datepicker {
  z-index: 10055 !important; }

.datepicker table td {
  color: #000;
  font-weight: 300  !important;
  font-family: 'Open Sans' !important; }

.datepicker table th {
  color: #333;
  font-family: 'Open Sans' !important;
  font-weight: 400  !important; }

.datepicker.dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef; }

.datepicker .fa-angle-left:before {
  content: ""/*rtl:""*/; }

.datepicker .fa-angle-right:before {
  content: ""/*rtl:""*/; }

/***
Bootstrap Daterangepicker
***/
.modal-open .daterangepicker {
  z-index: 10055 !important; }

.daterangepicker {
  margin-top: 4px; }
  .daterangepicker .input-mini {
    width: 100% !important;
    outline: none !important; }

.daterangepicker td {
  text-shadow: none; }

.daterangepicker td.active {
  background-color: #4b8df8;
  background-image: none;
  filter: none; }

.daterangepicker th {
  font-weight: 400;
  font-size: 14px; }

.daterangepicker .ranges input[type="text"] {
  width: 70px !important;
  font-size: 11px;
  vertical-align: middle; }

.daterangepicker .ranges label {
  font-weight: 300;
  display: block; }

.daterangepicker .ranges {
  width: 170px; }
  .daterangepicker .ranges ul > li.active {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }

.daterangepicker .ranges .btn {
  margin-top: 10px; }

.daterangepicker.dropdown-menu {
  padding: 5px; }

.daterangepicker .ranges li {
  color: #333; }

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #4b8df8 !important;
  border: 1px solid #4b8df8 !important;
  color: #fff; }

.daterangepicker .range_inputs input {
  margin-bottom: 0 !important; }

.daterangepicker .fa-angle-right:before {
  content: ""/*rtl:""*/; }

.daterangepicker .fa-angle-left:before {
  content: ""/*rtl:""*/; }

/***
Bootstrap  Datetimepicker
***/
.datetimepicker table td {
  color: #000;
  font-weight: 300  !important;
  font-family: 'Open Sans' !important; }

.datetimepicker table th {
  font-family: 'Open Sans' !important;
  font-weight: 400  !important; }

.datetimepicker.dropdown-menu {
  padding: 5px; }

.datetimepicker .active {
  background-color: #4b8df8 !important;
  background-image: none !important;
  filter: none !important; }

.datetimepicker .active:hover {
  background-color: #2678FC !important;
  background-image: none !important;
  filter: none !important; }

.datetimepicker .fa-angle-left:before {
  content: ""/*rtl:""*/; }

.datetimepicker .fa-angle-right:before {
  content: ""/*rtl:""*/; }

/* change z-index when opened in modal */
.modal-open .datetimepicker {
  z-index: 10055; }

/***
Bootstrap Editable
***/
.editable-input table,
.editable-input table th,
.editable-input table td,
.editable-input table tr {
  border: 0 !important; }

.editable-input .combodate select {
  margin-bottom: 5px; }

/***
Jansy File Input plugin css changes
***/
.fileinput {
  margin-bottom: 0; }
  .fileinput .close {
    float: none; }
  .fileinput .input-group {
    white-space: nowrap;
    overflow: hidden; }

/***
Bootstrap Markdown
***/
.md-input {
  padding: 5px !important;
  border-bottom: 0 !important;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px; }

.md-editor {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .md-editor .btn-toolbar {
    margin-left: 0px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0; }
    .md-editor .btn-toolbar .btn-group .btn-sm.btn {
      padding-bottom: 3px; }
    .md-editor .btn-toolbar .btn-group .btn-primary {
      padding-top: 5px;
      padding-bottom: 7px; }

.md-editor.active {
  border: 1px solid #999999;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

/***
Error state for WYSIWYG Editors
***/
.has-error .md-editor {
  border: 1px solid #fbe1e3 !important; }

.has-success .md-editor {
  border: 1px solid #abe7ed !important; }

.has-warning .md-editor {
  border: 1px solid #f9e491 !important; }

/***
Bootstrap Modal
***/
/* fix: content shifting to the right on modal open */
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-top,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .modal-scrollable {
  overflow-y: auto !important; }

.modal-scrollable {
  overflow: hidden !important; }

/* BS 3.3.1 fix */
.modal-backdrop {
  position: fixed;
  bottom: 0; }

.modal-scrollable {
  overflow: hidden !important;
  z-index: 10051;
  overflow-y: scroll !important; }

/***
Bootstrap Select
***/
.bootstrap-select .btn {
  border-color: #c2cad8; }

.has-error .bootstrap-select .btn {
  border-color: #fbe1e3; }

.has-success .bootstrap-select .btn {
  border-color: #abe7ed; }

.has-warning .bootstrap-select .btn {
  border-color: #f9e491; }

.bootstrap-select.open .btn {
  border-color: #93a1bb; }

.bootstrap-select.open.dropup .btn {
  border-color: #93a1bb; }

.bootstrap-select .btn:focus {
  outline: none !important;
  outline-offset: 0; }

.bootstrap-select.btn-group .dropdown-menu {
  margin-top: 1px; }

.bootstrap-select.btn-group .dropdown-menu > li > dt > .text {
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 14px; }

.bootstrap-select.btn-group .dropdown-menu .text-muted {
  color: #999 !important; }

.bootstrap-select .caret {
  border: 0;
  width: auto;
  height: auto;
  margin-top: -10px !important; }

.bootstrap-select .caret:before {
  content: "\f107";
  display: inline-block;
  border: 0;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal; }

.bootstrap-select .selected i {
  color: #aaa; }

.bootstrap-select .dropdown-menu {
  z-index: 9999 !important; }

/***
Bootstrap Switch 
***/
.bootstrap-switch {
  border-color: #c2cad8; }
  .bootstrap-switch.bootstrap-switch-focused {
    box-shadow: none;
    border-color: #c2cad8; }

/***
Color variants
***/
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #337ab7; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #659be0; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #36c6d3; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background: #F1C40F; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #ed6b75; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #444;
  background: #e8eaef; }

/* Bootstrap switch on portlet headers */
.portlet:not(.light) > .portlet-title > .actions > .bootstrap-switch {
  border-color: transparent;
  margin-top: 0px; }

/***
Bootstrap Time Picker
***/
.bootstrap-timepicker-widget table td a {
  padding: 4px 0; }

.bootstrap-timepicker-widget input,
.bootstrap-timepicker-widget input:focus {
  outline: none !important;
  border: 0; }

.modal-open .bootstrap-timepicker-widget {
  z-index: 10055 !important; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:before,
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  top: auto; }

/***
Toastr Notifications
***/
.toast {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #51a351; }

.toast-error {
  background-color: #bd362f; }

.toast-info {
  background-color: #2f96b4; }

.toast-warning {
  background-color: #f89406; }

.toast .toast-close-button {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  text-indent: -100000px;
  width: 11px;
  height: 16px;
  background-repeat: no-repeat !important;
  background-image: url("../img/portlet-remove-icon-white.png") !important; }

.toast-top-center {
  top: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px; }
  .toast-top-center .toast {
    margin: 0 !important; }

.toast-bottom-center {
  bottom: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px; }
  .toast-bottom-center .toast {
    margin: 0 !important; }

/***
Bootstrap Wizard
***/
.form-wizard .progress {
  margin-bottom: 30px; }

.form-wizard .steps {
  padding: 10px 0;
  margin-bottom: 15px;
  background-color: #fff;
  background-image: none;
  filter: none;
  border: 0px;
  box-shadow: none; }
  .form-wizard .steps > li > a.step {
    background-color: #fff;
    background-image: none;
    filter: none;
    border: 0px;
    box-shadow: none; }
    .form-wizard .steps > li > a.step:hover {
      background: none; }
    .form-wizard .steps > li > a.step > .number {
      background-color: #eee;
      display: inline-block;
      text-align: center !important;
      font-size: 16px;
      font-weight: 300;
      padding: 11px 15px 13px 15px;
      margin-right: 10px;
      height: 45px;
      width: 45px;
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      border-radius: 50% !important; }
    .form-wizard .steps > li > a.step > .desc {
      display: inline-block;
      font-size: 16px;
      font-weight: 300; }
      .form-wizard .steps > li > a.step > .desc > i {
        display: none; }
  .form-wizard .steps > li.active > a.step .number {
    background-color: #36c6d3;
    color: #fff; }
  .form-wizard .steps > li.active > a.step .desc {
    color: #333; }
  .form-wizard .steps > li.done > a.step .number {
    background-color: #F1C40F;
    color: #fff; }
  .form-wizard .steps > li.done > a.step .desc {
    color: #333; }
    .form-wizard .steps > li.done > a.step .desc i {
      font-size: 12px;
      font-weight: normal;
      color: #999;
      display: inline-block; }

@media (min-width: 768px) and (max-width: 1200px) {
  /* 768px & 1200px */
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block; } }

@media (max-width: 768px) {
  /* 768px */
  .form-wizard .steps > li > a {
    text-align: left; } }

/*!
 * Tab drop for Bootstrap
 */
.tabbable-tabdrop .nav-tabs,
.tabbable-tabdrop .nav-pills {
  position: relative; }
  .tabbable-tabdrop .nav-tabs .caret,
  .tabbable-tabdrop .nav-pills .caret {
    display: none; }
  .tabbable-tabdrop .nav-tabs .tabdrop .dropdown-toggle i,
  .tabbable-tabdrop .nav-pills .tabdrop .dropdown-toggle i {
    font-size: 14px; }

.tabbable-tabdrop .dropdown-menu:after {
  right: 10px;
  left: auto; }

.tabbable-tabdrop .dropdown-menu:before {
  right: 9px;
  left: auto; }

/***
wysihtml5
***/
.wysihtml5-sandbox {
  width: 100% !important; }

.wysihtml5-toolbar li {
  margin: 0px;
  height: 29px; }

.wysihtml5-toolbar li .dropdown-menu {
  margin-top: 5px; }

/***
Error state for WYSIWYG Editors
***/
.has-error .wysihtml5-sandbox {
  border: 1px solid #fbe1e3 !important; }

.has-success .wysihtml5-sandbox {
  border: 1px solid #abe7ed !important; }

.has-warning .wysihtml5-sandbox {
  border: 1px solid #f9e491 !important; }

/***
Error state for WYSIWYG Editors
***/
.note-editor {
  border: 1px solid #c2cad8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .note-editor .note-toolbar {
    border-bottom: 1px solid #c2cad8;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0; }
  .note-editor .note-statusbar {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px; }
    .note-editor .note-statusbar .note-resizebar {
      border-top: 1px solid #c2cad8; }
  .note-editor.fullscreen {
    z-index: 11000;
    width: 100% !important; }
  .note-editor .dropdown-menu:before {
    left: 9px;
    right: auto; }
  .note-editor .dropdown-menu:after {
    left: 10px;
    right: auto; }

.note-link-dialog .checkbox {
  margin-left: 20px; }

.has-error .note-editor {
  border: 1px solid #fbe1e3 !important; }

.has-success .note-editor {
  border: 1px solid #abe7ed !important; }

.has-warning .note-editor {
  border: 1px solid #f9e491 !important; }

/***
Bootstrap GtreeTable
***/
.gtreetable .dropdown-menu {
  margin-top: 0px; }
  .gtreetable .dropdown-menu:after,
  .gtreetable .dropdown-menu:before {
    display: none !important; }

.gtreetable .node-action .form-control {
  position: relative;
  top: 2px;
  display: inline-block; }

.gtreetable .node-icon-selected,
.gtreetable .node-icon-handle,
.gtreetable .node-icon-ce,
.gtreetable .node-icon-type {
  opacity: 0.6 ;
  filter: alpha(opacity=60) ; }

/***
Modify bootstrap-tagsinput plugin's css
***/
.bootstrap-tagsinput .tag [data-role="remove"] {
  box-shadow: none;
  font-family: arial; }
  .bootstrap-tagsinput .tag [data-role="remove"]:after {
    font-family: arial; }
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    text-decoration: none;
    box-shadow: none; }

/***
Bootbox
***/
.bootbox .bootbox-close-button {
  margin-top: 0px !important; }

/*--------------------------------------------------
    [CSS Animation Amchart]
----------------------------------------------------*/
.CSSAnimationChart {
  width: 100%;
  height: 500px; }
  .CSSAnimationChart a {
    color: #bbb !important;
    font-size: 12px !important; }

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite; }

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px; } }

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px; } }

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite; }

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px; } }

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px; } }

.amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out; }

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0 \0;
  /* fixes IE prob */
  stroke-dashoffset: 0 \0;
  /* fixes IE prob */
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s; }

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0px; } }

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0px; } }

/*--------------------------------------------------
    [Mao Amchart]
----------------------------------------------------*/
.mapChart {
  width: 100%;
  height: 500px; }
  .mapChart a {
    color: #bbb !important;
    font-size: 12px !important; }

/*--------------------------------------------------
    [Column Line]
----------------------------------------------------*/
.columnLine {
  width: 100%;
  height: 350px;
  font-size: 11px; }

.amcharts-graph-graph2 .amcharts-graph-stroke {
  stroke-dasharray: 4px 5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite; }

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px; } }

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px; } }

/*--------------------------------------------------
    [Animated Pie Chart]
----------------------------------------------------*/
.animated-pie-chart {
  width: 100%;
  height: 350px;
  font-size: 11px; }

/***
CKEditor css changes
***/
.cke {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .cke .cke-top {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0; }
  .cke .cke-bottom {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px; }

.cke_bottom,
.cke_inner,
.cke_top,
.cke_reset,
.cke_dialog_title,
.cke_dialog_footer,
.cke_dialog {
  background-image: none !important;
  filter: none;
  border-top: 0;
  border-bottom: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none; }

.cke_dialog_ui_button,
.cke_dialog_tab {
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important; }

.cke_dialog_ui_button:hover,
.cke_dialog_tab:hover {
  text-decoration: none;
  text-shadow: none; }

.cke_dialog_ui_input_text {
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

.cke_combo_button,
.cke_button,
.cke_toolbar,
.cke_toolgroup {
  background-image: none !important;
  filter: none !important;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

.cke_button,
.cke_combo_button,
.cke_panel_grouptitle,
.cke_hc.cke_panel_listItem a {
  background-image: none !important;
  filter: none;
  text-shadow: none; }

.cke_button:hover,
.cke_combo_button:hover {
  background-color: #ddd; }

.cke_toolbar_break {
  background-image: none !important;
  filter: none !important;
  border: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important; }

/***
Error state for WYSIWYG Editors
***/
.has-error .cke {
  border: 1px solid #fbe1e3 !important; }

.has-success .cke {
  border: 1px solid #abe7ed !important; }

.has-warning .cke {
  border: 1px solid #f9e491 !important; }

/***
Clockface
***/
.modal-open .clockface {
  z-index: 10055 !important; }

.clockface .cell .inner.active,
.clockface .cell .outer.active {
  background-color: #4b8df8 !important;
  background-image: none;
  filter: none; }

/***
Datatables general customization
***/
.table-checkable tr > th:first-child,
.table-checkable tr > td:first-child {
  text-align: center;
  max-width: 50px;
  min-width: 40px;
  padding-left: 0;
  padding-right: 0; }

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 2px solid #e7ecf1;
  outline: none !important; }

table.dataTable.no-footer {
  border-bottom-color: #e7ecf1; }

table.dataTable tr.heading > th {
  background-color: #fbfcfd; }

table.dataTable td.sorting_1,
table.dataTable td.sorting_2,
table.dataTable td.sorting_3,
table.dataTable th.sorting_1,
table.dataTable th.sorting_2,
table.dataTable th.sorting_3 {
  background: #fbfcfd !important; }

/***
Extended pagination
***/
.paging_bootstrap_extended {
  margin: 5px 0 0 0 !important;
  padding: 0 !important;
  float: none !important;
  font-size: 13px; }

.dataTables_extended_wrapper {
  margin-top: 10px; }
  .dataTables_extended_wrapper .seperator {
    padding: 0 2px; }
  .dataTables_extended_wrapper .table.dataTable {
    margin: 20px 0 !important; }
  .dataTables_extended_wrapper div.dataTables_paginate,
  .dataTables_extended_wrapper div.dataTables_length,
  .dataTables_extended_wrapper div.dataTables_info {
    display: inline-block;
    float: none !important;
    padding: 0 !important;
    margin: 0 !important;
    position: static !important; }
  .dataTables_extended_wrapper .table-group-actions > span {
    font-size: 13px; }

@media (max-width: 480px) {
  .dataTables_extended_wrapper div.dataTables_paginate,
  .dataTables_extended_wrapper div.dataTables_length,
  .dataTables_extended_wrapper div.dataTables_info {
    display: block;
    margin-bottom: 10px !important; }
  .dataTables_extended_wrapper .seperator {
    display: none !important; } }

.dataTables_extended_wrapper div.dataTables_length label {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
  float: none !important;
  display: inline-block !important; }

.table-container .table-actions-wrapper {
  display: none; }

/***
Scroller Extension
***/
.dataTables_scroll {
  margin-bottom: 10px; }

.dataTables_scrollHead {
  border-bottom: 2px solid #e7ecf1 !important; }
  .dataTables_scrollHead thead th {
    border-bottom: 0 !important; }

.dataTables_scrollBody {
  border-bottom: 1px solid #e7ecf1 !important; }

.table-both-scroll .dataTables_scrollBody {
  border-bottom: 0 !important; }

/***
Datatables processing
***/
.dataTables_wrapper .dataTables_processing {
  width: 200px;
  display: inline-block;
  padding: 7px;
  left: 50%;
  margin-left: -100px;
  margin-top: 10px;
  text-align: center;
  color: #3f444a;
  border: 1px solid #e7ecf1;
  background: #eef1f5;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1); }

/***
Tool Buttons
***/
.dt-button-background {
  display: none !important; }

div.dt-button-collection {
  border: 1px solid #eee;
  background: #ffffff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0; }
  div.dt-button-collection > a.dt-button {
    background: none;
    padding: 8px 16px;
    font-weight: 300;
    margin: 1px 1px;
    box-shadow: none !important;
    border: 0 !important; }
    div.dt-button-collection > a.dt-button > span {
      font-size: 14px;
      color: #333; }
    div.dt-button-collection > a.dt-button.active {
      background: #f6f6f6 !important;
      box-shadow: none !important;
      border: 0 !important; }
      div.dt-button-collection > a.dt-button.active > span {
        color: #333; }

button.dt-button,
div.dt-button,
a.dt-button {
  background-image: none !important;
  padding: 6px 12px !important; }
  button.dt-button:last-child,
  div.dt-button:last-child,
  a.dt-button:last-child {
    margin-right: 0; }

.dataTables_wrapper .dt-buttons {
  float: right; }
  .portlet.light .dataTables_wrapper .dt-buttons {
    margin-top: -64px; }
  .portlet.box .dataTables_wrapper .dt-buttons {
    margin-top: -50px; }

/***
Rot Details
***/
.dataTable .row-details.row-details-close {
  background: url("../img/datatable-row-openclose.png") no-repeat 0 0; }

.dataTable .row-details.row-details-open {
  background: url("../img/datatable-row-openclose.png") no-repeat 0 -23px; }

/***
Sorting
***/
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    display: none; }

table.dataTable thead .sorting {
  background: url("../plugins/datatables/images/sort_both.png") no-repeat center right; }

table.dataTable thead .sorting_asc {
  background: url("../plugins/datatables/images/sort_asc.png") no-repeat center right; }

table.dataTable thead .sorting_desc {
  background: url("../plugins/datatables/images/sort_desc.png") no-repeat center right; }

table.dataTable thead .sorting_asc_disabled {
  background: url("../plugins/datatables/images/sort_asc_disabled.png") no-repeat center right; }

table.dataTable thead .sorting_desc_disabled {
  background: url("../plugins/datatables/images/sort_desc_disabled.png") no-repeat center right; }

table.dataTable th:active {
  outline: none; }

/***
Fixed Header
***/
table.dataTable.fixedHeader-floating {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 1px solid #dee5ec;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1); }

.table-header-fixed thead > tr > th {
  border-top: 0; }

.table > tfoot > tr > th,
.table > tfoot > tr > td {
  border-bottom: 0;
  padding: 10px 18px;
  border-top: 1px solid #e7ecf1;
  font-weight: 600; }

/***
Responsive Datatables
***/
.table.dataTable.dt-responsive
.table.dataTable.responsive {
  width: 100%; }

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  text-align: center;
  width: 14px; }

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  margin-left: -8px;
  text-align: center;
  text-indent: 0;
  line-height: 0; }

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  box-shadow: none !important;
  background-color: #b0c1d2;
  font-weight: 300;
  vertical-align: middle;
  line-height: 16px;
  border: 0; }

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #36c6d3;
  font-weight: 300;
  vertical-align: middle;
  border: 0; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  top: 10px; }

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  text-align: center;
  margin-top: -8px; }

table.dataTable > tbody > tr.child ul li {
  border: 0; }

@-moz-document url-prefix() {
  .portlet-datatable .dataTables_wrapper .table-responsive {
    padding: 0 2px !important; } }

@media (max-width: 767px) {
  /* 767px */
  .portlet.box .dataTables_wrapper .dt-buttons,
  .portlet.light .dataTables_wrapper .dt-buttons {
    margin-top: 0;
    margin-bottom: 20px; } }

.table-scrollable .dataTable th .btn-group,
.table-scrollable .dataTable td .btn-group {
  position: absolute;
  margin-top: -2px; }

/***
Dropzone css changes(new in v1.1.1)
***/
.dropzone {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.dropzone-file-area {
  border: 2px dashed #028AF4;
  background: white;
  padding: 20px;
  margin: 0 auto;
  text-align: center; }

.dz-hidden-input {
  left: 0; }

@media (max-width: 768px) {
  /* 768px */
  .dropzone-file-area {
    width: auto; } }

/***
Fancy box fix overlay fix
***/
.fancybox-overlay {
  z-index: 100000; }

.fancybox-opened {
  z-index: 100001; }

/***
FuelUX Spinners
***/
.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px; }
  .page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-up {
    border-radius: 0;
    border-top-right-radius: 4px !important; }
  .page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-down {
    border-radius: 0;
    border-bottom-right-radius: 4px; }

/***
Fullcalendar
***/
/* External events */
.external-event {
  display: inline-block;
  cursor: move;
  margin-bottom: 5px;
  margin-left: 5px; }

.fc-scroller {
  overflow-y: auto;
  overflow-x: hidden; }

.fc-month-view .fc-scroller {
  height: auto !important; }

.portlet .event-form-title {
  font-size: 16px;
  margin-top: 4px;
  font-weight: 400;
  margin-bottom: 10px; }

.portlet.calendar .fc-button {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  border: 0;
  height: 35px;
  padding: 5px 8px 8px 8px;
  margin-left: 2px;
  border-top-style: none;
  border-bottom-style: none;
  border-right-style: solid;
  border-left-style: solid;
  border-color: #ddd;
  background: transparent;
  color: #fff;
  top: -48px;
  outline: none !important;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0; }

.portlet.calendar .fc-toolbar {
  margin-bottom: 0px; }
  .portlet.calendar .fc-toolbar h2 {
    margin-top: 3px;
    font-size: 17px; }

.portlet.calendar .fc-header {
  margin-bottom: -21px; }

.portlet.calendar .fc-button-prev {
  padding-right: 10px;
  padding-left: 8px; }

.portlet.calendar .fc-button-next {
  padding-right: 8px;
  padding-left: 10px; }

.portlet.calendar .fc-button .fc-icon {
  height: 20px;
  top: -5px; }

.portlet.calendar .fc-button.fc-state-active,
.portlet.calendar .fc-button.fc-state-hover {
  color: #666;
  background-color: #fff; }

.portlet.calendar .fc-button.fc-state-disabled {
  color: #ddd; }

.portlet.calendar .fc-button .fc-icon-left-single-arrow {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 16px; }
  .portlet.calendar .fc-button .fc-icon-left-single-arrow:after {
    content: "";
    margin: 0; }
  .portlet.calendar .fc-button .fc-icon-left-single-arrow:before {
    content: "\f104"; }

.portlet.calendar .fc-button .fc-icon-right-single-arrow {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 16px; }
  .portlet.calendar .fc-button .fc-icon-right-single-arrow:after {
    margin: 0;
    content: ""; }
  .portlet.calendar .fc-button .fc-icon-right-single-arrow:before {
    content: "\f105"; }

.portlet.calendar .fc-text-arrow {
  font-size: 22px;
  font-family: "Courier New", Courier, monospace;
  vertical-align: baseline; }

.portlet.calendar .fc-event {
  border: 0px;
  background-color: #69a4e0;
  color: #fff; }
  .portlet.calendar .fc-event .fc-content {
    border: 0px;
    padding: 5px 7px; }
  .portlet.calendar .fc-event .fc-time {
    float: left;
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    padding-right: 5px; }
  .portlet.calendar .fc-event .fc-title {
    text-align: left;
    float: left;
    color: #fff;
    font-size: 14px;
    font-weight: 300; }

.portlet.calendar .fc-header-title h2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #111; }

.portlet.calendar .fc-widget-header {
  background-image: none;
  filter: none;
  background-color: #eee;
  text-transform: uppercase;
  font-weight: 300; }

.portlet.calendar.light .fc-button {
  top: -60px;
  color: #666;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 35px; }
  .portlet.calendar.light .fc-button .fc-text-arrow {
    margin-top: -6px;
    display: inline-block; }
  .portlet.calendar.light .fc-button.fc-state-active,
  .portlet.calendar.light .fc-button.fc-state-hover {
    color: #333;
    border-bottom: 2px solid #36c6d3; }
  .portlet.calendar.light .fc-button.fc-state-disabled {
    color: #aaa; }

.portlet.calendar .mobile .fc-button {
  padding: 0px 6px 20px 6px;
  margin-left: 2px;
  border: 0;
  background-color: #ddd;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #000;
  text-shadow: none;
  text-align: center; }
  .portlet.calendar .mobile .fc-button.fc-state-hover,
  .portlet.calendar .mobile .fc-button.fc-state-active {
    background-color: #eee; }
  .portlet.calendar .mobile .fc-button.fc-state-disabled {
    color: #bbb; }

.portlet.calendar .mobile .fc-button-prev {
  margin-right: 5px;
  margin-top: -2px; }

.portlet.calendar .mobile .fc-button-next {
  margin-right: -0px;
  margin-top: -2px; }

.portlet.calendar .mobile .fc-header-space {
  margin: 0px;
  padding: 0px;
  width: 0px; }

.portlet.calendar .mobile .fc-header-left {
  position: absolute;
  z-index: 10; }
  .portlet.calendar .mobile .fc-header-left .fc-button {
    top: -2px; }

.portlet.calendar .mobile .fc-header-right {
  position: relative;
  right: 0; }
  .portlet.calendar .mobile .fc-header-right .fc-button {
    top: 35px; }

.portlet.calendar .mobile .fc-content {
  margin-top: 53px; }

/***
Google Maps
***/
.gmaps {
  height: 300px;
  width: 100%;
  /* important!  bootstrap sets max-width on img to 100% which conflicts with google map canvas*/ }
  .gmaps img {
    max-width: none; }

#gmap_static div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
  display: block;
  height: 300px; }

#gmap_routes_instructions {
  margin-top: 10px;
  margin-bottom: 0px; }

/***
Gritter notification modify
***/
#gritter-notice-wrapper {
  right: 1px !important; }

.gritter-close {
  left: auto !important;
  right: 3px !important; }

.gritter-title {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300; }

/***
Dashboard Charts(new in v1.2.1)
***/
.easy-pie-chart,
.sparkline-chart {
  text-align: center; }

.sparkline-chart {
  margin-top: 15px;
  position: relative; }

.easy-pie-chart .number {
  font-size: 14px !important;
  font-weight: 300;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 75px;
  height: 75px;
  line-height: 75px; }
  .easy-pie-chart .number canvas {
    position: absolute;
    top: 0;
    left: 0; }

.sparkline-chart .number {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px; }

.sparkline-chart .title,
.easy-pie-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px; }

.sparkline-chart .title:hover,
.easy-pie-chart .title:hover {
  color: #666;
  text-decoration: none; }

.sparkline-chart .title > i,
.easy-pie-chart .title > i {
  margin-top: 5px; }

/***
jQuery File Upload
***/
.blueimp-gallery .close {
  background-image: url("../img/portlet-remove-icon-white.png") !important;
  margin-top: -2px; }

.blueimp-gallery .prev,
.blueimp-gallery .next {
  border-radius: 23px !important; }

/***
jQuery Multi Select
***/
.ms-container .ms-list {
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.ms-container .ms-optgroup-label {
  font-size: 14px; }

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  font-size: 13px; }

.ms-container .ms-list.ms-focus {
  border-color: #999999;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  color: #333;
  background-color: #eee; }

.ms-container .form-control {
  margin-bottom: 5px; }

/***
jQuery Notific8 Plugin
***/
.jquery-notific8-message {
  font-size: 13px; }

[class*="jquery-notific8"],
[class*="jquery-notific8"]:after,
[class*="jquery-notific8"]:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.right .jquery-notific8-close-sticky span,
.left .jquery-notific8-close-sticky span {
  font-size: 10px; }

.jquery-notific8-heading {
  font-weight: 300;
  font-size: 16px; }

/***
Password Strength(in v1.4)
***/
.password-strength .password-verdict {
  display: inline-block;
  margin-top: 6px;
  margin-left: 5px; }

.password-strength .progress {
  margin-top: 5px;
  margin-bottom: 0; }

.password-strength .progress-bar {
  padding: 2px; }

/***
jQuery Slimscroll
***/
.scroller {
  padding: 0px;
  margin: 0px;
  padding-right: 12px;
  overflow: hidden; }

.scroller-footer {
  margin-top: 10px; }
  .scroller-footer:before,
  .scroller-footer:after {
    content: " ";
    display: table; }
  .scroller-footer:after {
    clear: both; }

.portlet-body .slimScrollBar {
  margin-right: 0px; }

/***
jQuery Sparkline
***/
.jqstooltip {
  width: auto !important;
  height: auto !important; }

.easy-pie-chart,
.sparkline-chart {
  text-align: center; }

.sparkline-chart {
  margin-top: 15px;
  position: relative; }

.easy-pie-chart .number {
  font-size: 16px;
  font-weight: 300;
  width: 85px;
  margin: 0 auto; }

.sparkline-chart .number {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px; }

.sparkline-chart .title,
.easy-pie-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px; }

.sparkline-chart .title:hover,
.easy-pie-chart .title:hover {
  color: #666;
  text-decoration: none; }

.sparkline-chart .title > i,
.easy-pie-chart .title > i {
  margin-top: 5px; }

/***
jQuery UI Sliders(new in v1.1.1)
***/
.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px; }

.ui-slider-vertical {
  width: 11px; }

.ui-slider-horizontal .ui-slider-handle {
  top: -3px; }

.ui-slider-vertical .ui-slider-handle {
  left: -3px; }

.ui-slider-vertical,
.ui-slider-handle {
  filter: none !important;
  background-image: none !important; }

/***
JQVMAP Plugin
***/
.jqvmap-zoomout,
.jqvmap-zoomin {
  height: 16px;
  width: 16px;
  background-color: #666; }

.vmaps {
  position: relative;
  overflow: hidden;
  height: 300px; }

/***
Jstree
***/
.jstree-default .jstree-clicked {
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none; }

.jstree-default .jstree-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none; }

.jstree-default .jstree-wholerow-clicked,
.jstree-wholerow .jstree-wholerow-clicked {
  background: none;
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none; }

.jstree-default .jstree-wholerow-hovered,
.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none; }

.jstree-icon.icon-lg {
  margin-top: 1px; }

.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c"; }

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
  background-repeat: no-repeat; }

.vakata-context,
.vakata-context ul {
  padding: 0;
  min-width: 125px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Segoe UI",Helvetica, Arial, sans-serif;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef; }

.vakata-context li {
  border: 0; }
  .vakata-context li a {
    padding: 0 10px;
    border: 0; }
    .vakata-context li a i {
      display: none; }
    .vakata-context li a .vakata-contextmenu-sep {
      display: none; }

.vakata-context .vakata-context-hover > a,
.vakata-context li a:hover {
  background-color: #eee;
  color: #333;
  box-shadow: none;
  margin: 0; }
  .vakata-context .vakata-context-hover > a .span,
  .vakata-context .vakata-context-hover > a .ins,
  .vakata-context li a:hover .span,
  .vakata-context li a:hover .ins {
    border: 0 !important; }

.vakata-context li a span,
.vakata-context li a ins {
  display: none;
  border: 0 !important; }

.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0; }

.jstree-rename-input {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  outline: none !important;
  padding: 2px 6px !important;
  margin-right: -4px !important; }

/***
NoUIRangeSlider
***/
.noUi-horizontal {
  margin: 9px 0; }

/***
Color variants
***/
.noUi-primary .noUi-connect {
  background: #337ab7; }

.noUi-info .noUi-connect {
  background: #659be0; }

.noUi-success .noUi-connect {
  background: #36c6d3; }

.noUi-warning .noUi-connect {
  background: #F1C40F; }

.noUi-danger .noUi-connect {
  background: #ed6b75; }

.noUi-default .noUi-connect {
  background: #bac3d0; }

/* Conect slider */
/* For this slider, disable the 'origin' size. */
.noui-connect .noUi-origin {
  right: auto;
  width: 0; }

/* Position the bar and color it. */
.noui-connect .connect {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #80C9F5;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45); }

/* When the slider is moved by tap,
   transition the connect bar like the handle. */
.noui-connect.noUi-state-tap .connect {
  -webkit-transition: left 300ms, right 300ms;
  transition: left 300ms, right 300ms; }

/**
noUI Tooltips
**/
.noUi-handle .noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  font-size: 12px;
  border-radius: 3px;
  background: #fff;
  top: -55px;
  padding: 5px;
  left: -10px;
  text-align: center;
  min-width: 50px; }
  .noUi-handle .noUi-tooltip strong {
    display: block;
    padding: 2px; }

/***
Google reCaptcha
***/
.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px; }

iframe[src="about:blank"] {
  display: none; }

.select2-container--bootstrap .select2-search--dropdown .select2-search__field,
.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-right: 3px; }

.select2-container--bootstrap .select2-results__group {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  font-family: "Open Sans", sans-serif; }

.select2-container--bootstrap .select2-selection {
  font-family: "Open Sans", sans-serif; }

.modal-open .select2-dropdown {
  z-index: 10060; }

.modal-open .select2-close-mask {
  z-index: 10055; }

.modal-open .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  width: auto !important; }

.select2-result-repository {
  padding-top: 4px;
  padding-bottom: 3px; }

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 10px; }

.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 2px; }

.select2-result-repository__meta {
  margin-left: 70px; }

.select2-result-repository__title {
  color: black;
  font-weight: bold;
  word-wrap: break-word;
  line-height: 1.1;
  margin-bottom: 4px; }

.select2-result-repository__forks, .select2-result-repository__stargazers {
  margin-right: 1em; }

.select2-result-repository__forks, .select2-result-repository__stargazers, .select2-result-repository__watchers {
  display: inline-block;
  color: #aaa;
  font-size: 11px; }

.select2-result-repository__description {
  font-size: 13px;
  color: #777;
  margin-top: 4px; }

.select2-results__option--highlighted .select2-result-repository__title {
  color: white; }

.select2-results__option--highlighted .select2-result-repository__forks, .select2-results__option--highlighted .select2-result-repository__stargazers, .select2-results__option--highlighted .select2-result-repository__description, .select2-results__option--highlighted .select2-result-repository__watchers {
  color: #c1d7e9; }

/***
jQuery Uniform
***/
.checker {
  margin-top: -2px !important;
  margin-right: 2px !important; }

.checker input,
.radio input {
  outline: none !important; }

div.checker.disabled span,
div.checker.disabled.active span {
  background-position: -152px -260px; }

div.checker.disabled:hover,
div.radio.disabled:hover {
  cursor: not-allowed; }

div.radio,
div.checker {
  margin-right: 0;
  margin-left: 3px; }

/***
Morris Charts
***/
.portlet-body-morris-fit {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -15px; }
  .portlet-body-morris-fit > svg {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -ms-border-radius: 0 0 4px 4px;
    -o-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px; }

.morris-hover.morris-default-style .morris-hover-row-label {
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: #7D8C9D;
  font-family: "Open Sans", sans-serif; }

.morris-hover.morris-default-style .morris-hover-point {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif; }

/***
iCheck
***/
.icheck-list > label {
  display: block;
  margin-bottom: 8px; }
  .icheck-list > label:last-child {
    margin-bottom: 0; }

.form-horizontal .icheck-inline {
  margin-top: 8px; }

.icheck-inline > label {
  display: inline-block;
  margin-left: 15px; }
  .icheck-inline > label:first-child {
    margin-left: 0; }

div[class*='icheckbox_'],
div[class*='iradio_'] {
  margin-right: 5px;
  top: -1px !important; }

.icheck-colors {
  padding: 0;
  margin: 0;
  list-style: none; }
  .icheck-colors > li {
    padding: 0;
    margin: 4px;
    float: left;
    display: inline-block;
    height: 20px;
    width: 20px;
    background: #000000;
    opacity: 0.6 ;
    filter: alpha(opacity=60) ; }
    .icheck-colors > li:first-child {
      margin-left: 0; }
    .icheck-colors > li:hover {
      opacity: 1 ;
      filter: alpha(opacity=100) ;
      cursor: pointer; }
    .icheck-colors > li.active {
      height: 26px;
      margin-top: 0;
      opacity: 0.6 ;
      filter: alpha(opacity=60) ; }
    .icheck-colors > li.red {
      background: #d54e21; }
    .icheck-colors > li.green {
      background: #78a300; }
    .icheck-colors > li.blue {
      background: #0e76a8; }
    .icheck-colors > li.aero {
      background: #9cc2cb; }
    .icheck-colors > li.grey {
      background: #73716e; }
    .icheck-colors > li.orange {
      background: #f70; }
    .icheck-colors > li.yellow {
      background: #fc0; }
    .icheck-colors > li.pink {
      background: #ff66b5; }
    .icheck-colors > li.purple {
      background: #6a5a8c; }

/***
AngularJS UI-select
***/
.ui-select-match-close {
  margin-top: 5px !important;
  margin-left: 5px !important; }

.minicolors-swatch {
  border: 0; }

/***
Codemirror Plugin
***/
.CodeMirror {
  border: 1px solid #eee;
  height: auto; }

/***
Prism Plugin
***/
pre[class*="language-"] {
  padding: 10px;
  background: none !important;
  border: 1px solid #f1f4f7 !important;
  box-shadow: none !important;
  margin: 0; }
  pre[class*="language-"] code {
    background: none !important;
    box-shadow: none !important;
    border: 0 !important; }

.socicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.socicon-btn:empty
.socicon:empty {
  width: 1em; }

.socicon-btn {
  position: relative;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  width: 44px;
  height: 44px;
  padding-top: 11px;
  font-size: 24px;
  border: 1px solid #e7ecf1;
  color: #3f444a;
  transition: background, color 0.3s; }
  .socicon-btn.socicon-solid {
    border: 0;
    width: 46px;
    height: 46px;
    padding-top: 12px; }
  .socicon-btn.socicon-btn-circle {
    border-radius: 44px !important; }
  .socicon-btn:hover {
    transition: background, color 0.3s;
    text-decoration: none; }
  .socicon-btn.socicon-lg {
    width: 54px;
    height: 54px;
    padding-top: 13px;
    font-size: 28px; }
    .socicon-btn.socicon-lg.socicon-solid {
      border: 0;
      width: 55px;
      height: 55px;
      padding-top: 13px; }
  .socicon-btn.socicon-sm {
    width: 30px;
    height: 30px;
    padding-top: 8px;
    font-size: 16px; }
    .socicon-btn.socicon-sm.socicon-solid {
      border: 0;
      width: 30px;
      height: 30px;
      padding-top: 8px; }

.socicons .socicon-btn {
  margin-bottom: 5px; }

.socicon-twitter:before {
  content: "a"; }

.socicon-facebook:before {
  content: "b"; }

.socicon-google:before {
  content: "c"; }

.socicon-pinterest:before {
  content: "d"; }

.socicon-foursquare:before {
  content: "e"; }

.socicon-yahoo:before {
  content: "f"; }

.socicon-skype:before {
  content: "g"; }

.socicon-yelp:before {
  content: "h"; }

.socicon-feedburner:before {
  content: "i"; }

.socicon-linkedin:before {
  content: "j"; }

.socicon-viadeo:before {
  content: "k"; }

.socicon-xing:before {
  content: "l"; }

.socicon-myspace:before {
  content: "m"; }

.socicon-soundcloud:before {
  content: "n"; }

.socicon-spotify:before {
  content: "o"; }

.socicon-grooveshark:before {
  content: "p"; }

.socicon-lastfm:before {
  content: "q"; }

.socicon-youtube:before {
  content: "r"; }

.socicon-vimeo:before {
  content: "s"; }

.socicon-dailymotion:before {
  content: "t"; }

.socicon-vine:before {
  content: "u"; }

.socicon-flickr:before {
  content: "v"; }

.socicon-500px:before {
  content: "w"; }

.socicon-instagram:before {
  content: "x"; }

.socicon-wordpress:before {
  content: "y"; }

.socicon-tumblr:before {
  content: "z"; }

.socicon-blogger:before {
  content: "A"; }

.socicon-technorati:before {
  content: "B"; }

.socicon-reddit:before {
  content: "C"; }

.socicon-dribbble:before {
  content: "D"; }

.socicon-stumbleupon:before {
  content: "E"; }

.socicon-digg:before {
  content: "F"; }

.socicon-envato:before {
  content: "G"; }

.socicon-behance:before {
  content: "H"; }

.socicon-delicious:before {
  content: "I"; }

.socicon-deviantart:before {
  content: "J"; }

.socicon-forrst:before {
  content: "K"; }

.socicon-playstore:before {
  content: "L"; }

.socicon-zerply:before {
  content: "M"; }

.socicon-wikipedia:before {
  content: "N"; }

.socicon-apple:before {
  content: "O"; }

.socicon-flattr:before {
  content: "P"; }

.socicon-github:before {
  content: "Q"; }

.socicon-chimein:before {
  content: "R"; }

.socicon-friendfeed:before {
  content: "S"; }

.socicon-newsvine:before {
  content: "T"; }

.socicon-identica:before {
  content: "U"; }

.socicon-bebo:before {
  content: "V"; }

.socicon-zynga:before {
  content: "W"; }

.socicon-steam:before {
  content: "X"; }

.socicon-xbox:before {
  content: "Y"; }

.socicon-windows:before {
  content: "Z"; }

.socicon-outlook:before {
  content: "1"; }

.socicon-coderwall:before {
  content: "2"; }

.socicon-tripadvisor:before {
  content: "3"; }

.socicon-appnet:before {
  content: "4"; }

.socicon-goodreads:before {
  content: "5"; }

.socicon-tripit:before {
  content: "6"; }

.socicon-lanyrd:before {
  content: "7"; }

.socicon-slideshare:before {
  content: "8"; }

.socicon-buffer:before {
  content: "9"; }

.socicon-rss:before {
  content: ","; }

.socicon-vkontakte:before {
  content: ";"; }

.socicon-disqus:before {
  content: ":"; }

.socicon-houzz:before {
  content: "+"; }

.socicon-mail:before {
  content: "@"; }

.socicon-patreon:before {
  content: "="; }

.socicon-paypal:before {
  content: "-"; }

.socicon-playstation:before {
  content: "^"; }

.socicon-smugmug:before {
  content: "¨"; }

.socicon-swarm:before {
  content: "$"; }

.socicon-triplej:before {
  content: "*"; }

.socicon-yammer:before {
  content: "&"; }

.socicon-stackoverflow:before {
  content: "("; }

.socicon-drupal:before {
  content: "#"; }

.socicon-odnoklassniki:before {
  content: "."; }

.socicon-android:before {
  content: "_"; }

.socicon-meetup:before {
  content: "]"; }

.socicon-persona:before {
  content: ")"; }

.test {
  color: #e1e5ec;
  border-color: #2ab4c0;
  background: #e43a45; }
